<template>
  <div class="main-tabs">
    <div ref="tabs" class="main-tabs__container">
      <a-tabs
        v-model="activeTabId"
        type="card"
        class="main-tabs__card"
        @change="onTabChange"
      >
        <a-tab-pane
          v-for="tab in tabs"
          :key="tab.id"
          :tab="tab.name"
        >
          <template v-show="!getTabLoading && tab.apps">
            <div class="main-tabs__apps">
              <div ref="apps-container" class="main-tabs__apps-container">
                <a-icon @click="editViewscreen(tab)" type="setting" class="main-tabs__settings-icon" />
                <div
                  class="main-tabs__apps-tab-content"
                  :style="`max-width: ${tabAppsWidth}px`"
                  ref="apps"
                >
                  <div
                    v-for="app in tab.apps"
                    :key="app.application_id"
                    :ref="`app-${app.application_id}`"
                    class="main-tabs__apps-item"
                  >
                    <a-icon
                      :type="appIcon(app)"
                      :class="`main-tabs__apps-item__icon ${appTextClass(app)}`"
                    />
                    <span>{{ app.name }}</span>
                  </div>
                </div>
                <div
                  v-if="showAppsDots"
                  class="main-tabs__apps-item main-tabs__apps-item-dots"
                >
                  <a-icon
                    type="ellipsis"
                    class="main-tabs__apps-item__icon"
                    style="color: #595959; margin-right: 0;"
                  />
                </div>
              </div>
              <a-button type="primary" icon="plus" :loading="updateTabLoading" @click="addSection()">
                Section
              </a-button>
            </div>
            <transition-group v-if="tab.sections && tab.sections.length" tag="div" class="grid" type="transition" name="flip-grid">
              <div v-for="section in getActiveTabSections" :key="section.id" class="top-section">
                <record-section
                  v-if="['links_small', 'links_medium', 'links_large'].includes(section.type.name)"
                  :key="section.id"
                  :section-id="section.id"
                  :section-type="section.type"
                  :is-active="section.is_active"
                  :index="getActiveTabSections.findIndex((el) => el.id === section.id)"
                  :max-index="getActiveTabSections.length"
                  :records-data="section.records"
                  :title="section.localizations[0] ? section.localizations[0].title : '-'"
                  :langs="langs"
                  @editSection="handleEditSection(section)"
                  @duplicateSection="handleDuplicateSection(section)"
                  @moveDownSection="sectionShift(section, 1)"
                  @moveUpSection="sectionShift(section, -1)"
                  @changeStatusSection="changeStatusSection(section)"
                />
                <matches-section
                  v-if="['matches'].includes(section.type.name)"
                  :section-type="section.type"
                  :is-active="section.is_active"
                  :title="section.localizations[0] ? section.localizations[0].title : '-'"
                  :index="getActiveTabSections.findIndex((el) => el.id === section.id)"
                  :max-index="getActiveTabSections.length"
                  @editSection="handleEditSection(section)"
                  @moveDownSection="sectionShift(section, 1)"
                  @moveUpSection="sectionShift(section, -1)"
                  @changeStatusSection="changeStatusSection(section)"
                />
              </div>
            </transition-group>
            <div v-else class="empty-section">No sections available</div>
          </template>
          <spinner v-if="getTabLoading && !tab.apps" />
        </a-tab-pane>
        <a-button slot="tabBarExtraContent" class="add-section" @click="addViewscreen()">
          <a-icon type="plus" />
        </a-button>
      </a-tabs>
    </div>
    <a-modal
      v-model="sectionModal"
      :title="modalSectionTitle"
      :footer="null"
      class="section-modal"
      width="450px"
      :destroyOnClose="true"
    >
      <section-modal
        ref="linkSectionModal"
        :section-obj="linkSectionObj"
        :langs="langs"
        :activeTabId="this.activeTabId"
        @createSection="handleCreateSection"
        @updateSection="handleUpdateSection"
        @deleteSection="handleDeleteSection"
        @closeSectionModal="sectionModal = false"
      />
    </a-modal>
    <a-modal
      v-model="duplicationSectionModal"
      title="Dublicate section"
      :footer="null"
      class="section-modal"
      width="450px"
      :destroyOnClose="true"
    >
      <duplication-section-modal
        ref="duplicationSectionModal"
        :section-obj="duplicationObj"
        :viewscreens="tabs"
        @duplicateSection="duplicateSection"
        @closeDuplicationSectionModal="duplicationSectionModal = false"
      />
    </a-modal>
  </div>
</template>

<script>
import TopService from '@/services/api/apiTopService'
import MainService from '@/services/api/apiMainService'

export default {
  props: ['tabs', 'getTabLoading', 'createTabFlag', 'sortAfterChangeDataTab'],
  components: {
    spinner: () => import('./spinner.vue'),
    'record-section': () => import('./recordSection.vue'),
    'matches-section': () => import('./matchesSection.vue'),
    'section-modal': () => import('../modals/sectionModal.vue'),
    'duplication-section-modal': () => import('../modals/duplicationSectionModal.vue'),
  },
  data: () => ({
    activeTabSections: [],
    linkSectionObj: null,
    updateTabLoading: false,
    sectionModal: false,
    duplicationSectionModal: false,
    duplicationObj: null,
    activeTabId: null,
    showAppsDots: false,
    widthContainer: 450,
    langs: [],
    apps: [],
    appsVisible: [],
    numberOfVisibleApps: 100,
  }),
  watch: {
    sortAfterChangeDataTab(val) {
      if (val) {
        this.sortSections()
        this.$nextTick(() => {
          if (this.numberOfVisibleApps !== 100) {
            this.checkAppsContainerWidth()
          }
        })
      }
    },
    getTabLoading(val) {
      if (!val) {
        this.activeTabSections = this.tabs[this.getIndexActiveTab].sections
        this.updateCurrentApps()
      }
    },
    tabs: {
      handler(newValue) {
        this.updateCurrentApps()
        this.$nextTick(() => {
          this.checkAppsContainerWidth()
        })
        // При удалении таба
        if (newValue.length && !newValue.find(tab => tab.id === this.activeTabId)) {
          this.activeTabId = newValue[0].id
          return
        }
        // При добавлении таба
        if (this.createTabFlag) {
          this.activeTabId = newValue[newValue.length - 1].id
          this.onTabChange(newValue[newValue.length - 1].id)
        }
      },
      deep: true,
    },
    apps(arr) {
      if (arr.length) {
        this.$nextTick(() => {
          this.checkAppsContainerWidth()
        })
      }
    },
  },
  computed: {
    tabAppsWidth() {
      if (!this.$refs['tabs']) return this.widthContainer
      this.resizePage()
      return this.widthContainer - 260
    },
    isTheAppVisible() {
      return this.numberOfVisibleApps
    },
    modalSectionTitle() {
      if (this.linkSectionObj && this.linkSectionObj.id) {
        return `Edit section [#${this.linkSectionObj.id}]`
      }
      return 'Add section'
    },
    activeTabData() {
      return this.tabs.find(tab => tab.id === this.activeTabId)
    },
    getIndexActiveTab() {
      return this.tabs.findIndex(tab => tab.id === this.activeTabId)
    },
    getActiveTabApps() {
      return this.tabs[this.getIndexActiveTab]?.apps || []
    },
    getActiveTabSections() {
      return this.activeTabSections
    },
  },
  methods: {
    onTabChange(targetKey) {
      this.showAppsDots = false
      this.langs = this.activeTabData.langs
      this.$emit('changeTab', targetKey)
    },
    handleCreateSection(createdSection) {
      this.sectionModal = false
      this.updateAfterAddSection(this.activeTabId, createdSection)
      this.sortSections()
    },
    handleEditSection(section) {
      this.linkSectionObj = JSON.parse(JSON.stringify(section))
      this.sectionModal = true
    },
    handleDuplicateSection(section) {
      this.duplicationSectionModal = true
      this.duplicationObj = section
    },
    duplicateSection(section, tabId) {
      this.duplicationSectionModal = false
      this.duplicationObj = null
      if (this.activeTabId === tabId) {
        this.updateAfterAddSection(this.activeTabId, section)
        this.sortSections()
      }
    },
    async updateAfterAddSection(tabId, newSection) {
      const activeTab = this.tabs.find(tab => tab.id === tabId)
      activeTab.sections.unshift(newSection)
      this.activeTabSections = activeTab.sections
      await this.updateTabData(activeTab)
      this.onTabChange(this.activeTabId)
    },
    handleUpdateSection(updatedSection) {
      const sectionIndex = this.activeTabData.sections.findIndex(section => section.id === updatedSection.id)
      this.activeTabData.sections[sectionIndex] = updatedSection
      this.onTabChange(this.activeTabId)
      this.sectionModal = false
    },
    handleDeleteSection(deletedId) {
      this.activeTabSections.splice(this.activeTabSections.findIndex(el => el.id === deletedId), 1)
      this.onTabChange(this.activeTabId)
      this.sectionModal = false
    },
    async updateTabData(tab) {
      tab.sections = tab.sections.filter(section => section.id !== 0)
      const data = {
        name: tab.name,
        apps_ids: tab.apps.map(app => app.application_id),
        sections_ids: tab.sections.map(section => section.id),
      }
      this.updateTabLoading = true
      try {
        await MainService.updateTopTab(this.activeTabId, data).then((res) => {
          this.checkAppsContainerWidth()
          return res.data.data
        }).catch(error => {
          console.log(error)
        })
      } finally {
        this.updateTabLoading = false
      }
    },
    sectionShift(section, value) {
      const indexSection = this.activeTabSections.findIndex(el => el.id === section.id)
      this.moveArrayItemToNewIndex(this.activeTabSections, indexSection, indexSection + value)
      this.sortSections()
    },
    moveArrayItemToNewIndex(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      return arr
    },
    async sortSections() {
      const sectionSort = this.activeTabSections.map((section, index, sectionArr) => {
        return {
          section_id: section.id,
          sort: sectionArr.length - index,
        }
      })

      if (!sectionSort.length) return

      const sort = { sort: sectionSort }
      return TopService.sortSections(sort).then(() => {
        // this.$notification.success({
        //   message: 'Sections sort updated',
        //   description: '',
        // })
      }).catch(error => { console.log(error) })
    },
    async changeStatusSection(section) {
      section.is_active = !section.is_active
      const dataSection = {
        type_id: section.type.id,
        weight: section.weight,
        localizations: section.localizations,
        is_active: section.is_active,
      }
      return TopService.updateSection(section.id, dataSection).then((response) => {
        this.$notification.success({
          message: 'Section status updated',
          description: '',
        })
      }).catch(error => {
        this.$notification.error({
          message: 'Error while updating status section',
          description: error.message,
        })
      })
    },
    appTextClass(app) {
      switch (app.platform) {
        case 'ios':
          return 'text-primary'
        case 'android':
          return 'text-success'
        case 'web':
          return 'text-default'
      }
    },
    appIcon(app) {
      switch (app.platform) {
        case 'ios':
          return 'apple'
        case 'android':
          return 'android'
        case 'web':
          return 'global'
      }
    },
    addViewscreen() {
      this.$emit('addViewscreen')
    },
    editViewscreen(tab) {
      this.activeTabData.apps = tab.apps
      this.$emit('editViewscreen', this.activeTabData)
    },
    addSection() {
      this.linkSectionObj = null
      this.sectionModal = true
    },
    updateCurrentApps() {
      if (this.tabs[this.getIndexActiveTab]?.apps) {
        this.apps = this.tabs[this.getIndexActiveTab].apps
      }
    },
    checkAppsContainerWidth() {
      let width = 30
      this.numberOfVisibleApps = this.apps.length - 1
      let count = 0
      this.apps.forEach(app => {
        if (this.$refs[`app-${app.application_id}`][0]) {
          this.$refs[`app-${app.application_id}`][0].classList.remove('main-tabs__hide-app')
          this.$refs[`app-${app.application_id}`][0].classList.remove('main-tabs__apps-item-mr')
          if (this.tabAppsWidth > (width + this.$refs[`app-${app.application_id}`][0].offsetWidth + 10)) {
            width += this.$refs[`app-${app.application_id}`][0].offsetWidth + 10
            this.$refs[`app-${app.application_id}`][0].classList.add('main-tabs__apps-item-mr')
            if (this.tabAppsWidth > (width + this.$refs[`app-${app.application_id}`][0].offsetWidth + 10)) {
              count += 1
            }
          } else {
            this.$refs[`app-${app.application_id}`][0].classList.add('main-tabs__hide-app')
          }
        }
      })
      this.numberOfVisibleApps = count
      if ((this.numberOfVisibleApps + 1) < this.apps.length) {
        this.showAppsDots = true
      } else {
        this.showAppsDots = false
      }
    },
    moveDown(section) {
      this.$emit('moveDown', section)
    },
    moveUp(section) {
      this.$emit('moveUp', section)
    },
    resizePage() {
      this.widthContainer = this.$refs['tabs'].offsetWidth
    },
  },
  mounted() {
    this.activeTabId = this.tabs[0].id
    if (!this.getTabLoading) {
      this.$emit('getTabData', this.activeTabId)
      this.langs = this.activeTabData.langs
    }
    window.addEventListener('resize', this.resizePage)
    this.$nextTick(() => {
      this.resizePage()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizePage)
  },
}
</script>

<style lang="scss">
.main-tabs {
  &__container {
    overflow: hidden;
    & > .ant-tabs-card > .ant-tabs-content {
      margin-top: -17px;
    }
    &  > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
      border: 1px solid #e4e9f0;
      border-radius: 0 4px 4px 4px;
      background: #fff;
      padding: 16px;
    }
    & .ant-tabs-content > .ant-tabs-tabpane-inactive {
      border: none !important;
    }
    & > .ant-tabs-card > .ant-tabs-bar {
      display: flex;
      border-color: #fff;
      & .ant-tabs-extra-content {
        order: 1;
        float: none !important;
        display: flex;
        margin-bottom: -1px;
      }
    }
    & > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
      color: #595959;
      border-color: transparent;
      background: transparent;
      &:not(.ant-tabs-tab-active) {
        background-color: #F5F5F5 !important;
      }
    }
    & > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
      border-color: #fff;
      background: #fff;
    }
    & .ant-tabs-tab {
      margin-right: 2px !important;
    }
  }
  &__settings-icon {
    color: #1890ff;
    font-size: 16px;
    margin-right: 12px;
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      transform: scale(1.2) rotate(90deg);
    }
  }
  &__apps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__apps-container {
    display: flex;
    align-items: center;
  }
  &__apps-tab-content {
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__apps-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    color: #595959;
    background-color: #f5f5f5;
    cursor: default;
    &__icon {
      color: #1890ff;
      margin-right: 10px;
    }
    & span {
      white-space: nowrap;
    }
    &-mr {
      margin-right: 10px;
    }
    &-dots {
      height: 27px;
      & > i {
        transform: translateY(3px);
      }
    }
  }
  &__emty-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
  }
  &__hide-app {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
.add-section {
  height: 100% !important;
  align-self: center;
  border-bottom: none;
  background-color: #F5F5F5 !important;
  padding: 0 12px !important;
  & .anticon-plus {
    color: #595959;
  }
}
.empty-section {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #595959;
  font-size: 20px;
  padding: 20px;
}
</style>
